import React from "react";
import Contacts from "../img/contacts.svg";

const Contact = () => {
  return (
    <>
      <section id="intro" class="clearfix">
        <div class="container">
          <div class="intro-img">
            <img src={Contacts} alt="Contact" class="img-fluid" />
          </div>

          <div class="intro-info">
            <h2>
              Get
              <br />
              <span>in touch</span>With us{" "}
            </h2>
            <div>
              <a
                href="https://wa.me/918624922118"
                class="btn-get-started scrollto"
              >
                Chat
              </a>
              <a href="tel:+91 8624922118" class="btn-services scrollto">
                call
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div class="container-fluid">
          <div class="section-header">
            <h3>Contact Us</h3>
          </div>

          <div class="row wow fadeInUp">
            <div class="col-lg-6">
              <div class="map mb-4 mb-lg-0">            
              <iframe src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=Indexial Solutions 1st Floor, Opps. Of Jana Small Bank, Sahawas Road, Dnydeep Colony, Hingne Budrukh, Karve Nagar, Pune, Maharashtra 411052&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  width="600"
                  height="312"
                  frameborder="0"
                  style={{ border: "0" }}
                  allowfullscreen=""
                ></iframe>{" "}
              </div>
            </div>

            <div class="col-lg-6">
              <div class="row">
                <div class="col-md-5 info">
                  <i class="ion-ios-location-outline"></i>
                  <p>
                    <b> Our Office Location</b>
                    <br />
                    1st Floor,Opps. Of Jana
                    <br />
                    Small Bank,Sahawas Road
                    <br />
                    Dnydeep Colony, Hingane Br
                    <br />
                    Karve Nagar, Pune-52.
                  </p>
                </div>
                <div class="col-md-6 info">
                  <i class="ion-ios-telephone-outline"></i>
                  <p>
                    <b> Call us on</b>
                  
                    <br />
                    91 8624922118
                  </p>
                </div>
                <div class="col-md-4 info">
                  <i class="ion-ios-email-outline"></i>
                  <p>
                    {" "}
                    <b> Write us to </b>
                    <br /> For business inquires: info@indexial.in
                    <br />
                    For general queries: hello@indexial.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
